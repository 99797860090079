'use client'
import { Heart } from '@phosphor-icons/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { signIn, useSession } from 'next-auth/react'
import ToastMessage from 'react-hot-toast'

type FavouritesData = { favourite: string[] }

export function PersonaFavourite({ personaId }: { personaId: string }) {
  const { status, data: userData } = useSession()
  const isAuthenticated = status === 'authenticated'

  const { data } = useQuery<FavouritesData>({
    queryKey: ['user-replica-favourite', userData?.userId],
    queryFn: async () => {
      const res = await fetch('/api/user/favourite', {
        method: 'GET',
      })
      if (!res.ok) {
        throw new Error('Network response was not ok')
      }
      return await res.json()
    },
    placeholderData: { favourite: [] },
    enabled: isAuthenticated,
  })

  const isFavourite = data?.favourite.includes(personaId) ?? false

  const queryClient = useQueryClient()

  const { mutate: mutateFavourite } = useMutation({
    mutationFn: async ({ personaId, isFavourite }: { personaId: string; isFavourite: boolean }) => {
      if (isFavourite) {
        await fetch(`/api/user/favourite/${personaId}`, {
          method: 'POST',
        })
      } else {
        await fetch(`/api/user/favourite/${personaId}`, {
          method: 'DELETE',
        })
      }
    },
    onMutate: async ({ personaId, isFavourite }) => {
      await queryClient.cancelQueries({ queryKey: ['user-replica-favourite', userData?.userId] })

      const previousFavourites = queryClient.getQueryData<FavouritesData>([
        'user-replica-favourite',
        userData?.userId,
      ]) ?? { favourite: [] }

      const newFavourites = {
        favourite: isFavourite
          ? [...previousFavourites.favourite, personaId]
          : previousFavourites?.favourite.filter((id) => id !== personaId),
      }

      queryClient.setQueryData(['user-replica-favourite', userData?.userId], newFavourites) // Optimistic update

      return { previousFavourites, personaId, isFavourite, newFavourites }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user-replica-favourite', userData?.userId] })
    },
    onError(error, variables, context) {
      if (context?.previousFavourites) {
        queryClient.setQueryData(['user-replica-favourite', userData?.userId], context?.previousFavourites) // Rollback
      }
      console.error(error)
      ToastMessage.error(`Unable to ${variables ? 'add' : 'remove'} favourite`)
    },
  })

  const onToggleFavourite = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (!isAuthenticated) return signIn()
    return mutateFavourite({ personaId, isFavourite: !isFavourite })
  }

  return (
    <button
      type="button"
      className="absolute right-6 top-6 z-10 flex cursor-pointer flex-row items-center justify-center"
      onClick={onToggleFavourite}
    >
      <Heart size={20} weight={isFavourite ? 'fill' : 'regular'} color={isFavourite ? 'red' : 'white'} />
    </button>
  )
}
