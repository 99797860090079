'use client'

import Image from 'next/image'

const features = [
  '/assets/featured/ted.png',
  '/assets/featured/yahoo.png',
  '/assets/featured/bloomberg.png',
  '/assets/featured/hackernoon.png',
  '/assets/featured/producthunt.png',
  '/assets/featured/abc.svg',
  '/assets/featured/fox.svg',
  '/assets/featured/cbs.svg',
]

const Featured = () => {
  return (
    <div className="relative antialiased">
      <main className="relative flex flex-col justify-center overflow-hidden">
        <h2 className="pb-6 text-center font-quincycf text-3xl font-bold">Featured in</h2>
        <div
          x-data="{}"
          x-init="$nextTick(() => {
                        let ul = $refs.logos;
                        ul.insertAdjacentHTML('afterend', ul.outerHTML);
                        ul.nextSibling.setAttribute('aria-hidden', 'true');
                    })"
          className="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
        >
          <ul className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-8">
            {features.map((image, index) => (
              <li className="list-none" key={index}>
                <Image src={image} width={158} height={48} alt={image} className="opacity-70 grayscale" />
              </li>
            ))}
          </ul>

          <ul
            className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-8"
            aria-hidden="true"
          >
            {features.map((image, index) => (
              <li className="list-none" key={index}>
                <Image src={image} width={158} height={48} alt={image} className="opacity-70 grayscale" />
              </li>
            ))}
          </ul>
        </div>
      </main>
    </div>
  )
}

export default Featured
